<template>
  <div class="topmenu">
  <div style="text-align: right;">
    <v-btn text to="/integritypolicy" class="integrate">Integritetsmeddelande</v-btn>
    <!-- <v-btn
      style="margin-left: 10px;"
      to="/upplevelser"
      class="closeexp adminlargebutton"
      >Upplevelser</v-btn
    > -->
    <v-btn
    style="margin-left: 10px;"
      to="/register"
      class="closeexp adminlargebutton"
      >Bli skapare</v-btn
    >
    <v-btn
      style="margin-left: 10px;"
      to="/Login"
      class="closeexp adminlargebutton"
      >Logga in</v-btn
    >
  </div>
    <div class="text-center">
      <img src="./../assets/Hubblaloggo.png" class="headimg" />
    </div>
    <div class="container">
      <div class="headlinehubbla">
        <h5>Välkommen att spela och skapa innehåll i Hubbla! </h5>
        <p>
          Upptäck Västmanlands kultur, historia och konst med mobilappen Hubbla.
          Genom att öppna upp och dyka in i Hubbla kan du vid Bondtorget i
          Västerås få lyssna till historien om den gamla silverskatten som
          hittades där en gång. Du kan också få se träd som förkolnades i den
          stora branden 2014 och höra röster som läser upp händelser från
          branden; när du vandrar mellan träden ändrar sig rösterna. Vid torget
          där konstverket Asea-strömmen lyser med sin frånvaro kan du i stället
          få se en ny cykelström utförd av en konstnär i Västerås. Det är några
          av de saker du kan uppleva genom Hubbla, men det kommer att komma
          mera, så håll utkik!
        </p>
        <div class="py-8 text-center">
          <img style="width: 100%" src="./../assets/Hubbla-showcase.png" />
        </div>
        <div class="d-flex text-center appcontainer">
          <div class="appcontainer-inner">
            <a href="https://play.google.com/store/apps/details?id=com.Mindflower.Digitalrundvandring" target="_blank">
              <img class="appicons" src="./../assets/Hubbla-android.png" />
            </a> 
          </div>
          <div class="appcontainer-inner">
            <a href="https://apps.apple.com/us/app/hubbla/id1613490569" target="_blank">
                <img class="appicons" src="./../assets/Hubbla-appstore.png" />
            </a>
          </div>
        </div>
        <h5 class="pt-8">Teman och innehållsformer</h5>
        <p>
          Hubblan innehåller teman från olika områden som historia, konst,
          litteratur, musik och arkitektur. Upplevelserna är gjorda som AR (=
          augmented reality), film, ljud eller bild
        </p>
        <h5>Samla utmärkelser</h5>
        <p>
          Är historia ditt största intresse? Då kan du samla utmärkelser för
          just det eller kanske vill du bege dig till någonting i varje kommun,
          då kan du få en speciell utmärkelse för det.
        </p>
        <h5>Vill du göra en egen upplevelse?</h5>
        <p>
          Har du egna idéer om upplevelser som skulle passa i Hubbla? Dela gärna
          med dig av dem, både film, ljud och bild. Om du har en idé om en
          AR-upplevelse så kan du även skicka in den som idé. Så här funkar det med: 
          Film: mp4-fil, max 2 minuter lång och max 200 Mb stor. Ljud i mp3-format, 
          musik: max 3 minuter långt. Bild i jpg eller png-format: max storlek 5 Mb 
          och gärna stående eftersom det ska betraktas i en telefon. Genom att ha ett konto
          till Hubbla kan du logga in på sidan här och ladda upp ditt innehåll.
          Vi som arbetar med Hubbla kommer att kontrollera det som skickas in
          innan vi publicerar det.
        </p>
        <div class="d-flex justify-space-between">
          <v-btn to="/Register" class="closeexp adminlargebutton"
            >Bli skapare</v-btn
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>

<style scoped>
h1 {
  color: #7030a0;
  font-size: 50px;
  margin-bottom: 0;
}

.appicons {
  height: 60px;
}

h5 {
  color: #7030a0;
  font-size: 20px;
  font-weight: bold;
}
p {
  color: black;
  font-weight: normal;
  font-size: 16px;
}

.topmenu {
  padding: 20px
}


</style>