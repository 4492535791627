<template>
  <div>
    <Appbar></Appbar>
    <v-container style="min-width:370px; max-width:80%; margin:0px auto; padding:16px">
      <div class="container-fluid">
        <div>
          <v-form v-model="valid" ref="form" @submit.prevent="save">
              <!--ExperienceName Start-->
               <div class="row">
              <div class="col-md-6 col-lg-6 col-xl-6">
                <v-card class="rounded-lg">
              <div class="row mb-2">
                <v-col cols="6">
                  <v-text-field
                    class="ml-4"
                    autofocus
                    v-model="editedItem.name"
                    label="Upplevelsenamn *"
                    counter="50"
                    maxlength="50"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-select
                    class="label-color"
                    label="Typ av upplevelse *"
                    v-model="editedItem.experiencetypeId"
                    :items="dialoglists.experiencetypes"
                    item-text="description"
                    item-value="id"
                    prepend-icon="mdi-camera-image"
                    :rules="[rules.required]"
                  ></v-select>
                </v-col>
                <v-col cols="3">
                  <v-select
                    class="label-color mr-5"
                    label="Badges typ *"
                    v-model="editedItem.badgeId"
                    :items="dialoglists.badges"
                    :rules="[rules.required]"
                    item-text="description"
                    prepend-icon="mdi-medal"
                    item-value="id"
                  ></v-select>
                </v-col>
            </div>
              </v-card>
              </div>
            <v-col>
              <v-col>
                <!-- <v-col class="d-flex justify-end"> -->
              <div class="d-md-flex justify-end">
                <v-card-actions
                v-if="
                  this.$store.getters.usertype == 0 ||
                    this.$store.getters.usertype == 3
                ">
              <div style="display: flex;">
                <v-btn class="mr-2"  @click="cancel()">
                  <v-icon small class="py-2 mr-1">mdi-cancel</v-icon>
                  Avbryt
                </v-btn>
                <v-btn class="mr-2" @click="checkForEmptyDates(), checkRegionBeforeSave(false)">
                  <v-icon small class="py-2 mr-1">mdi-content-save</v-icon>
                  Publicera
                </v-btn>
                <v-btn class="mr-2"  @click="checkForEmptyDates(), checkRegionBeforeSave(true)">
                  <v-icon small class="py-2 mr-1">mdi-content-save</v-icon>
                  Spara
                </v-btn>
                <v-btn
                  @click="deleteItem()"
                  v-if="this.$store.getters.usertype == 3"
                >
                  <v-icon small class="py-2 mr-1">mdi-delete</v-icon>
                  Radera
                </v-btn>
              </div>
              </v-card-actions>
              <v-card-actions v-else>
                <v-spacer></v-spacer>
                <div>
                <button
                  style="margin-right: 5px;"
                  class="closeexp"
                  @click="cancel()"
                >
                  <v-icon style="color: #fff;" small class="py-2 mr-1"
                    >mdi-close</v-icon
                  >
                  Avbryt nu
                </button>
                <button
                  style="margin-right: 5px;"
                  class="saveexp"
                  @click="checkForEmptyDates(), checkRegionBeforeSave(true)"
                >
                  <v-icon style="color: #fff;" small class="py-2 mr-1"
                    >mdi-content-save</v-icon
                  >
                  Spara och fortsätt senare
                </button>
                <button class="saveexp"  @click="Save(false)">
                  <v-icon style="color: #fff;" small class="py-2 mr-1"
                    >mdi-content-save</v-icon
                  >
                  Skicka in
                </button>
              </div>
              </v-card-actions>
              </div>
              <!-- </v-col> -->

              </v-col>
                    </v-col>
                  </div>
                <!--ExperienceName Ends-->
                    <!--Map start-->
                <v-card class="rounded-lg">
                  <v-col style="padding-top: 0px;">
                      <div id="map" class="row">
                    <link href="https://api.mapbox.com/mapbox-gl-js/v2.10.0/mapbox-gl.css" rel="stylesheet">
                  </div>
                  </v-col>
                  <!--Map ends-->
                <!--Description Start-->
              <div class="row">
                <v-col>
                  <p class="ml-4">Beskriving av upplevelse *<br>(max 500 tecken)</p>
                  <v-textarea
                    counter="500"
                    style="padding-right:15px"
                    class="ml-4"
                    clear-icon="mdi-close-circle"
                    v-model="editedItem.description"
                    maxlength="500"
                    required
                  ></v-textarea>
                  </v-col>
                  <v-col>
                    <p>Markera på kartan där upplevelsen ska upptäckas</p>
                    <v-text-field
                      :value="coordinates"
                      class="label-color"
                      placeholder="Koordinater"
                      prepend-icon="mdi-map-marker"
                      readonly
                    ></v-text-field>
                  <v-text-field
                    class="label-color"
                    readonly
                    placeholder="Kommun"
                    :value="location[0]"
                    :rules="[rules.required]"
                    prepend-icon="mdi-image-filter-hdr"
                    item-text="countyName"
                  ></v-text-field>
                  <!--Experience type & Badge type ends-->

                  <!-- Testfields for Location start-->

                    <v-text-field
                      :value="location[1]"
                      class="label-color"
                      placeholder="Region"
                      prepend-icon="mdi-map-marker-radius"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col>
                  <v-col class="justify-start" style="padding-top:0px">
                <v-row
                  v-if="
                    this.$store.getters.usertype == 0 ||
                      this.$store.getters.usertype == 3
                  ">

                  <v-col>
                    <div style="display: flex">
                      <v-progress-circular
                    class="progressStyle"
                    :size="300"
                    :width="10"
                    v-model="loadingSave"
                    v-show="loadingSave"
                    color="purple"
                    indeterminate
                  ></v-progress-circular>
                        <div v-if="this.editedItem.experiencetypeId === 1">
                        <label for="file-upload" @change="onFileChange">
                          <span class="v-btn uploadbutton" >
                            <v-icon>mdi-upload</v-icon>
                            Ladda upp fil för IOS
                          </span>
                        </label>
                        <!-- <div> -->
                          <br>
                        <v-chip
                          v-if="chip1"
                          class="uploadbutton"
                          style="margin-top:10px; margin-bottom:10px;"
                          label
                          outlined>
                       <v-icon>mdi-file-outline</v-icon>{{editedItem.fileName.substring(0, 25)}}
                        </v-chip>
                       <v-icon v-if="chip1" @click="deleteattachement()">mdi-delete-outline</v-icon>
                      <!-- </div> -->
                        <br>
                        <label for="file-upload2" @change="onFileChange2">
                          <span class="v-btn uploadbutton">
                            <v-icon>mdi-upload</v-icon>
                            Ladda upp fil för Android
                          </span>
                        </label>
                        <template>
                          <br>
                          <v-chip
                                 v-if="chip2"
                                  class="uploadbutton"
                                  style="margin-top:10px"
                                  label
                                  outlined>
                                  <v-icon>mdi-file-outline</v-icon> {{editedItem.fileName2.substring(0,25)}}
                                </v-chip>
                                <v-icon style="margin-top:10px" v-if="chip2" @click="deleteattachement2();">mdi-delete-outline</v-icon>
                      </template>

                      </div>
                      <div v-else>

                        <label for="file-upload" @change="onFileChange">
                          <p style="margin-bottom: 5px;">Max 200 mb</p>
                          <span class="v-btn uploadbutton">
                            <v-icon>mdi-upload</v-icon>
                            Ladda upp fil
                          </span>
                    </label>
                         <v-chip
                          v-if="chip1"
                          :href="preview(this.editedItem.fileLink)"
                          target="_blank"
                          class="uploadbutton"
                          label
                          style=" width:150px"
                          outlined>
                          <v-icon>mdi-file-outline</v-icon>{{editedItem.fileName.substring(0,15)}}
                        </v-chip>
                        <v-progress-circular
                        v-if="loading > 0 && loading < 100"
                        :rotate="360"
                        width="6"
                        v-model="loading"
                        color="blue"
                      ></v-progress-circular>
                        <v-icon v-if="chip1"  @click="deleteattachement()">mdi-delete-outline</v-icon>
                        <br>
                          <label for="file-upload2" v-if="this.editedItem.experiencetypeId === 2">
                          <span class="v-btn uploadbutton" style="width:150px;margin-top:10px ">
                            <v-icon>mdi-subtitles-outline</v-icon>
                           Undertexter
                          </span>
                        </label>
                        <v-chip
                          v-if="chip3"
                          class="uploadbutton"
                          label
                          style="width:150px; margin-top:10px"
                          outlined>
                          <v-icon>mdi-file-outline</v-icon>{{editedItem.subtitles.substring(0, 15)}}
                        </v-chip>
                        <v-icon v-if="chip3 && this.editedItem.experiencetypeId === 2" style="margin-top:10px;" @click="deleteattachement3()">mdi-delete-outline</v-icon>
                        <v-progress-circular
                        v-if="loading2 > 0 && loading2 < 100"
                        :rotate="360"
                        width="6"
                        v-model="loading2"
                        color="blue"
                      ></v-progress-circular>




                        <!-- <v-chip
                          v-if="chip1"
                          :href="'https://hubblastoragestage.blob.core.windows.net/media/' + this.editedItem.id + '_' + this.editedItem.fileName"
                          target="_blank"
                          class="uploadbutton"
                          label
                          style="margin-top: 5px; width:150px"
                          outlined>
                          <v-icon>mdi-file-outline</v-icon>{{editedItem.fileName.substring(0,15)}}
                        </v-chip>
                        <v-progress-circular
                        v-show="loading"
                        v-model="loading"
                        indeterminate
                        color="purple"
                      ></v-progress-circular>
                        <v-icon v-if="chip1" style = "margin-top: 5px;" @click="deleteattachement()">mdi-delete-outline</v-icon> -->


                        <!-- <v-chip
                          v-if="chip3"
                          class="uploadbutton"
                          label
                          style="margin-top: 5px; margin-left:31px; width:138px"
                          outlined>
                          <v-icon>mdi-file-outline</v-icon>{{editedItem.subtitles.substring(0, 13)}}
                        </v-chip>
                        <v-icon v-if="chip3 && this.editedItem.experiencetypeId === 2" style = "margin-top: 5px;" @click="deleteattachement3()">mdi-delete-outline</v-icon> -->


                          <div style="width: 150px" v-if="this.editedItem.experiencetypeId != 5">
                          <v-select
                          prepend-icon="mdi-screen-rotation"
                          :items="rotationValues"
                          item-value="value"
                          v-model="editedItem.rotation"
                          label="Rotation"/>
                        </div>
                          <!-- <template>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
          placeholder="123"
        >
          {{editedItem.rotation}}
        </v-btn>
      </template>
      <v-list           v-model="editedItem.rotation">
        <v-list-item
          v-for="(item, value) in rotationValues"
          :key="value"
        >
          <v-list-item-title>{{ item.value }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

</template> -->
                      </div>
                      <!-- <div class="help-tip">
                        <p>
                          Film: Försök att hålla filmen relativt kort och koncis
                          i förhållande till sin placering och innehåll. Filmen
                          får vara som längst 5 minuter lång. Stående format är
                          att föredra men det går bra med liggande oxå. Spela in
                          i minst 720p men gärna 1080p. Filmen ska komprimeras
                          med h.264 codec i mp4-format.
                          <br />
                          <br />

                          Bild: Tänk på att bilden ska vara mobilanpassad.<br />
                          <br />

                          Ljud: Storlek, Försök att hålla ljudklippet relativt
                          kort och koncist i förhållande till sin placering och
                          innehåll. Ljudklippet får vara som längst 5 minuter
                          långt. <br />
                          <br />

                          AR: (Maila en beskrivning av önskad AR-upplevelse till
                          fredrik.jakobsson@mindflower.se).<br />
                          <br />
                        </p>
                      </div> -->
                    </div>
                    <input
                      id="file-upload"
                      type="file"
                      @change="onFileChange"
                    />
                    <input
                      id="file-upload2"
                      type="file"
                      @change="onFileChange2"
                    />
                    <input
                      id="file-uploadSubtitles"
                      type="file"
                      @change="onFileChangeSubtitles"
                    />
                    <!-- <label>{{ editedItem.fileName }}</label> -->
                    <!-- <label style="padding-left:55px">{{ editedItem.fileName2 }}</label> -->
                    <!-- <p style="margin:0%"><strong>Max 200mb</strong></p> -->
                  </v-col>
                </v-row>
                <v-row v-else>
                  <v-col>
                    <div style="display: flex;">
                      <div v-if="this.editedItem.experiencetypeId === 1">
                        <h4>Kontakta mindflower för mer info</h4>
                        <!-- <label for="file-upload">
                            <span class="uploadbuttoncreator">
                              <v-icon style="color: #fff">mdi-upload</v-icon>
                              Ladda upp fil för IOS
                            </span>
                          </label>
                          <label for="file-upload2">
                            <span class="uploadbuttoncreator">
                              <v-icon style="color: #fff">mdi-upload</v-icon>
                              Ladda upp fil för Andriod
                            </span>
                          </label> -->
                      </div>
                      <div v-else>
                        <label for="file-upload">
                          <p style="margin-bottom: 5px;">Max 200 mb</p>
                          <span class="v-btn uploadbutton">
                            <v-icon>mdi-upload</v-icon>
                            Ladda upp fil
                          </span>
                        </label>
                        <br>
                        <v-chip
                          v-if="chip1"
                          class="uploadbutton"
                          style="margin-top:10px; margin-bottom:10px;"
                          label
                          outlined>
                       <v-icon>mdi-file-outline</v-icon>{{editedItem.fileName.substring(0, 25)}}
                        </v-chip>
                       <v-icon v-if="chip1" @click="deleteattachement()">mdi-delete-outline</v-icon>
                        <!-- <label for="file-upload">
                          <v-btn>
                            <v-icon style="color: #fff">mdi-upload</v-icon>
                            Ladda upp fil
                          </v-btn>
                        </label> -->
                      </div>
                      <div>
                        <v-progress-circular
                          v-show="loading"
                          v-model="loading"
                          indeterminate
                          color="blue"
                        ></v-progress-circular>
                      </div>
                      <div>
                        <!-- <div class="help-tip">
                          <p>
                            Film: Försök att hålla filmen relativt kort och
                            koncis i förhållande till sin placering och
                            innehåll. Filmen får vara som längst 5 minuter lång.
                            Stående format är att föredra men det går bra med
                            liggande oxå. Spela in i minst 720p men gärna 1080p.
                            Filmen ska komprimeras med h.264 codec i mp4-format.
                            <br />
                            <br />

                            Bild: Tänk på att bilden ska vara mobilanpassad.<br />
                            <br />

                            Ljud: Storlek, Försök att hålla ljudklippet relativt
                            kort och koncist i förhållande till sin placering
                            och innehåll. Ljudklippet får vara som längst 5
                            minuter långt. <br />
                            <br />

                            AR: (Maila en beskrivning av önskad AR-upplevelse
                            till fredrik.jakobsson@mindflower.se).<br />
                            <br />
                          </p>
                        </div> -->
                      </div>
                    </div>
                    <input
                      id="file-upload"
                      type="file"
                      @change="onFileChange"
                    />
                    <input
                      id="file-upload2"
                      type="file"
                      @change="onFileChange2"
                    />
                    <input
                      id="file-uploadSubtitles"
                      type="file"
                      @change="onFileChangeSubtitles"
                    />
                    <!-- <p class="pt-4"><strong>Max 200mb</strong></p> -->
                  </v-col>
                </v-row>
              </v-col>
                  </v-col>

               </div>
                </v-card>
               <!--Description Ends-->
               <v-card class="mt-4 rounded-lg">
              <!--Calendar starts-->
              <v-row class="justify-space-between">
                <v-col style="padding-left:10px; padding-top:10px">
                  <p class="pl-5">Datum & tid</p>
                  <v-menu
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto">
                    <vc-date-picker
                      v-model="editedItem.startTime"
                      :model-config="modelConfig"
                      mode="dateTime"
                      is24hr
                      color="purple"
                      firstDayOfWeek="2"
                    />
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="editedItem.startTime"
                        label="Startdatum"
                        class="ml-4"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        style="padding-bottom:8px; padding-right:15px"
                      ></v-text-field>
                    </template>
                  </v-menu>
                  <v-menu
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <vc-date-picker
                      v-model="editedItem.endTime"
                      :model-config="modelConfig"
                      mode="dateTime"
                      is24hr
                      color="purple"
                      firstDayOfWeek="2"
                    />
                    <template v-slot:activator="{ on, attrs1 }">
                      <v-text-field
                        :value="editedItem.endTime"
                        label="Slutdatum"
                        class="ml-4"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs1"
                        v-on="on"
                        style="padding-right:15px"
                      ></v-text-field>
                    </template>
                  </v-menu>
                </v-col>
                <!--Calendar ends-->

                <!--Weekday selection starts-->
                <v-col style="padding-top:0px; padding-left:10px">
                  <v-checkbox
                    class="ml-4"
                    v-model="isDisabledTime"
                    label="Aktiv tid"
                    @click="ifDisabledTime()"/>
                  <a-time-picker
                    style="padding-top:0px"
                    v-model="editedItem.weekDaysStartTime"
                    class="ml-4"
                    valueFormat="HH:mm"
                    placeholder="Starttid"
                    format="HH:mm"
                    :disabled="!isDisabledTime"/>
                  <a-time-picker
                    v-model="editedItem.weekDaysEndTime"
                    class="ml-4"
                    valueFormat="HH:mm"
                    placeholder="Sluttid"
                    format="HH:mm"
                    :disabled="!isDisabledTime"
                />
                  </v-col>

                <!--Weekday selection buttons-->
                <v-col style="padding-top:3px">
                <v-row>
                  <v-checkbox
                    style="padding-top:0px;"
                    v-model="isDisabled"
                    label="Aktiva veckodagar"
                    @click="ifDisabled(isDisabled)"
                  />
                  </v-row>
                  <!--Weekday buttons-->
                  <v-row>
                    <div v-for="day in weekDays" :key="day.id">
                      <v-btn
                        :value="day.id"
                        class="mr-2 weekdaysBtn"
                        :disabled="!isDisabled"
                        :class="buttonActiveColor(day)"
                        @click="
                          buttonActiveBool(day);
                          buttonActiveSendValue(day);">
                        {{ day.day }}
                      </v-btn>
                    </div>
                  </v-row>

                </v-col>

                <!--Weekday selection ends-->

                  <!-- Testfields for Location start-->

                   <!-- <v-text-field
                    style="padding-right:15px"
                      :value="location[0]"
                      class="label-color"
                      placeholder="Kommun"
                      readonly
                    ></v-text-field>
                    <v-text-field
                    style="padding-right:15px"
                      :value="location[1]"
                      class="label-color"
                      placeholder="Region"
                      readonly
                    ></v-text-field> -->

                    <!--Testfields for Location ends-->

              </v-row>
               </v-card>

              <v-row>
                <!-- Experience type & Badge type starts-->
                <v-col class="col-12 col-sm-6 col-md-3" style="padding-right:0px;padding-left:20px;padding-top:20px">

                  <p v-if="this.$store.getters.usertype == 0 || this.$store.getters.usertype == 3 && editedItem.isApproved === true" style="text-align: left; padding-right: 10px; margin-bottom: 0px;">
              Skapad av: {{editedItem.email}} <br>
              Godkänd av: {{editedItem.deniedOrApprovedBy}}</p>
            <p v-else-if="this.$store.getters.usertype == 0 || this.$store.getters.usertype == 3 && editedItem.isApproved === false" style="text-align: left; padding-right: 10px; margin-bottom: 0px;">
              Skapad av: {{editedItem.email}} <br>
              Nekad av: {{editedItem.deniedOrApprovedBy}}</p>
            <p v-else style="text-align: left; padding-right: 10px; margin-bottom: 0px;">
              Skapad av: {{editedItem.email}} <br>
            </p>
                    <!--Testfields for Location ends-->


                </v-col>
                <v-col style="padding-bottom:0px; padding-top:20px">
                  <v-card-actions
                v-if="
                  this.$store.getters.usertype == 0 ||
                    this.$store.getters.usertype == 3
                ">
              <v-spacer></v-spacer>
              <div>
                <v-btn class="mr-2"  @click="cancel()">
                  <v-icon small class="py-2 mr-1">mdi-cancel</v-icon>
                  Avbryt
                </v-btn>
                <v-btn class="mr-2" @click="checkForEmptyDates(), checkRegionBeforeSave(false)">
                  <v-icon small class="py-2 mr-1">mdi-content-save</v-icon>
                  Publicera
                </v-btn>
                <v-btn class="mr-2"  @click="checkForEmptyDates(), checkRegionBeforeSave(true)">
                  <v-icon small class="py-2 mr-1">mdi-content-save</v-icon>
                  Spara
                </v-btn>
                <v-btn
                  @click="deleteItem()"
                  v-if="this.$store.getters.usertype == 3"
                >
                  <v-icon small class="py-2 mr-1">mdi-delete</v-icon>
                  Radera
                </v-btn>
              </div>
              </v-card-actions>
              <v-card-actions v-else>
                <v-spacer></v-spacer>
                <div>
                <button
                  style="margin-right: 5px;"
                  class="closeexp"
                  @click="cancel()"
                >
                  <v-icon style="color: #fff;" small class="py-2 mr-1"
                    >mdi-close</v-icon
                  >
                  Avbryt nu
                </button>
                <button
                  style="margin-right: 5px;"
                  class="saveexp"
                  @click="checkForEmptyDates(), checkRegionBeforeSave(true)"
                >
                  <v-icon style="color: #fff;" small class="py-2 mr-1"
                    >mdi-content-save</v-icon
                  >
                  Spara och fortsätt senare
                </button>
                <button class="saveexp"  @click="checkForEmptyDates(), checkRegionBeforeSave(false)">
                  <v-icon style="color: #fff;" small class="py-2 mr-1"
                    >mdi-content-save</v-icon
                  >
                  Skicka in
                </button>
              </div>
              </v-card-actions>

                  <!--Coordinates field start-->

                    <!-- <v-text-field
                      :value="coordinates"
                      counter="100"
                      label="*"
                      class="label-color"
                      placeholder="Koordintater"
                      maxlength="100"
                    ></v-text-field> -->

                    <!--Coordinate field ends-->

                </v-col>
              </v-row>

              <v-row class="" style="float: right; padding-right: 10px; padding-left:10px; padding-bottom:3px; flex:auto" >

                <!-- <v-col class="d-flex justify-end"> -->
                <div class="d-md-flex align-start" style="padding-bottom:10px; padding-top:4px; padding-right:5px;">

                <!-- <v-card-actions
                v-if="
                  this.$store.getters.usertype == 0 ||
                    this.$store.getters.usertype == 3
                ">
              <v-spacer></v-spacer>
              <div>
                <v-btn class="mr-2"  @click="cancel()">
                  <v-icon small class="py-2 mr-1">mdi-cancel</v-icon>
                  Avbryt
                </v-btn>
                <v-btn class="mr-2" @click="checkForEmptyDates(), checkRegionBeforeSave(false)">
                  <v-icon small class="py-2 mr-1">mdi-content-save</v-icon>
                  Publicera
                </v-btn>
                <v-btn class="mr-2"  @click="checkForEmptyDates(), checkRegionBeforeSave(true)">
                  <v-icon small class="py-2 mr-1">mdi-content-save</v-icon>
                  Spara
                </v-btn>
                <v-btn
                  @click="deleteItem()"
                  v-if="this.$store.getters.usertype == 3"
                >
                  <v-icon small class="py-2 mr-1">mdi-delete</v-icon>
                  Radera
                </v-btn>
              </div>
              </v-card-actions>
              <v-card-actions v-else>
                <v-spacer></v-spacer>
                <div>
                <button
                  style="margin-right: 5px;"
                  class="closeexp"
                  @click="cancel()"
                >
                  <v-icon style="color: #fff;" small class="py-2 mr-1"
                    >mdi-close</v-icon
                  >
                  Avbryt nu
                </button>
                <button
                  style="margin-right: 5px;"
                  class="saveexp"
                  @click="Save(true)"
                >
                  <v-icon style="color: #fff;" small class="py-2 mr-1"
                    >mdi-content-save</v-icon
                  >
                  Spara och fortsätt senare
                </button>
                <button class="saveexp"  @click="Save(false)">
                  <v-icon style="color: #fff;" small class="py-2 mr-1"
                    >mdi-content-save</v-icon
                  >
                  Skicka in
                </button>
              </div>
              </v-card-actions> -->
              </div>
              <!-- </v-col> -->

            </v-row>
              <div style="padding-top: 0px; padding-left:8px; padding-bottom:0px">
           <h2>Så här fyller du i:</h2>
           <ul>
            <div style="font-style:italic">
              <li class="text-padding"><strong>Upplevelsenamn:</strong> Ge din upplevelse ett namn som beskriver vad innehållet är men inte för långt.</li>
              <li class="text-padding"><strong>Beskrivning av upplevelse:</strong> Här har du möjlighet att berätta mer utförligt om det som du visar. Denna text kommer visas innan man startar upplevelsen.</li>
              <li class="text-padding"><strong>Start & Slutdatum:</strong>
                Det kan vara bra om du vill att något ska vara tillgängligt först från en speciell dag eller du kanske bara vill att en sak ska finnas under en viss årstid
                </li>
              <li class="text-padding"><strong>Kommun:</strong> Välj i vilken kommun som upplevelsen finns</li>
              <li class="text-padding"><strong>Typ av upplevelse:</strong> Välj någon du tycker passar bäst. Du kan bara välja en.</li>
              <li class="text-padding"><strong>Badges:</strong> Välj någon du tycker passar bäst. Du kan bara välja en.</li>
              <li class="text-padding"><strong>Aktiv tid:</strong> Välj en tid när du önskar att upplevelsen ska vara aktiv under dagen </li>
              <li class="text-padding"><strong>Aktiva veckodagar:</strong> Välj vilka dagar som önskas när upplevelsen ska vara aktiv. (Lämna blank eller inaktiv om upplevelsen ska vara aktiv varje dag)</li>
              <li class="text-padding"><strong>Karta:</strong> Din upplevelse är alltid kopplad till en specifik plats.
                Vi använder samma koordinatsystem som Google Maps. </li>
            </div>
           </ul>
          </div>
          <h2 style="padding-left:10px">Om innehållet:</h2>
        <div style="padding-top: 0px; padding-left:15px; font-style:italic">
<li class="text-padding"><strong>Film:</strong> Försök att hålla filmen relativt kort och koncis i förhållande till sin placering och innehåll. Filmen får vara som längst 5 minuter lång. Stående format är att föredra men det går bra med liggande oxå. Spela in i minst 720p men gärna 1080p. Filmen ska komprimeras med h.264 codec i mp4-format.</li>
<li class="text-padding"><strong>Bild:</strong> Tänk på att bilden ska vara mobilanpassad. </li>
<li class="text-padding"><strong>Ljud:</strong> Storlek, Försök att hålla ljudklippet relativt kort och koncist i förhållande till sin placering och innehåll. Ljudklippet får vara som längst 5 minuter långt. </li>
<li class="text-padding"><strong>AR: </strong> Mejla en beskrivning av önskad AR-upplevelse till fredrik.jakobsson@mindflower.se.</li>
</div>
<h2 style="padding-left:10px">Tänk på: </h2>
<div style="padding-top: 0px; padding-left:10px; font-style:italic">
<ul class="my-0">
  <li>Dela inte innehåll med personuppgifter, till exempel bilder och filmer på personer eller namn, utan godkännande.</li>
  <li>Upphovsrätt. Kontrollera om du får dela ditt innehåll, t ex gamla bilder.</li>
</ul>
<br>
</div>


              <!-- <v-card-actions
                v-if="
                  this.$store.getters.usertype == 0 ||
                    this.$store.getters.usertype == 3
                "
              >
                <v-spacer></v-spacer>
                <v-btn tile outlined @click="cancel()">
                  <v-icon small class="py-2 mr-1">mdi-cancel</v-icon>
                  Avbryt
                </v-btn>
                <v-btn tile outlined @click="Save(false)">
                  <v-icon small class="py-2 mr-1">mdi-content-save</v-icon>
                  Klar för publicering
                </v-btn>
                <v-btn tile outlined @click="checkForEmptyDates(), Save(true)">
                  <v-icon small class="py-2 mr-1">mdi-content-save</v-icon>
                  Spara
                </v-btn>
                <v-btn
                  tile
                  outlined
                  @click="deleteItem()"
                  v-if="this.$store.getters.usertype == 3"
                >
                  <v-icon small class="py-2 mr-1">mdi-delete</v-icon>
                  Radera upplevelse
                </v-btn>
              </v-card-actions>
              <v-card-actions v-else>
                <v-spacer></v-spacer>
                <button
                  style="margin-right: 5px"
                  class="closeexp"
                  @click="cancel()"
                >
                  <v-icon style="color: #fff;" small class="py-2 mr-1"
                    >mdi-close</v-icon
                  >
                  Avbryt nu
                </button>
                <button
                  style="margin-right: 5px"
                  class="saveexp"
                  @click="Save(true)"
                >
                  <v-icon style="color: #fff;" small class="py-2 mr-1"
                    >mdi-content-save</v-icon
                  >
                  Spara och fortsätt senare
                </button>
                <button class="saveexp" @click="Save(false)">
                  <v-icon style="color: #fff;" small class="py-2 mr-1"
                    >mdi-content-save</v-icon
                  >
                  Skicka in
                </button>
              </v-card-actions> -->


          </v-form>
        </div>
      </div>
    </v-container>
  </div>
</template>
<script>
import { HTTP } from "@/plugins/backendAPI";
import "vue-date-pick/dist/vueDatePick.css";
import VCalendar from "v-calendar";
import Vue from "vue";
import axios from "axios";
import mapboxgl from "mapbox-gl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import Appbar from "../components/appbar.vue";
import router from "../router";
Vue.use(VCalendar, {
  componentPrefix: "vc"
});
const config = {
  Headers: {'Content-Type': 'multipart/form-data'},
  onUploadProgress: progressEvent => {
    var progress = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
    if (config.onProgress)
        config.onProgress(progress);

  }
}
export default {
  props: ["id"],
  data() {
    return {
      chip1: false,
      chip2: false,
      chip3: false,
      dialog: true,
      search: "",
      footerProps: { "items-per-page-options": [50, 100] },
      headers: [
        { text: "Namn", value: "name" },
        { text: "Kommun", value: "countyName" },
        { text: "Badge typ", value: "badgeName" },
        { text: "Upplevelse typ", value: "expTypeName" },
        { text: "Status", value: "isApproved" },
        { text: "Redigera", value: "action", sortable: false, width: 130 }
      ],
      creatorHeader: [
        { text: "Namn", value: "name" },
        { text: "Kommun", value: "countyName" },
        { text: "Badge typ", value: "badgeName" },
        { text: "Upplevelse typ", value: "expTypeName" },
        { text: "Status", value: "isApproved" },
        { text: "Redigera", value: "action", sortable: false, width: 130 }
      ],
      rotationValues: [
        { text: "0", value: 0},
        { text: "90", value: 90},
        { text: "180", value: 180},
        { text: "270", value: 270},
      ],
      rotationValuestest: [
        {value: 0},
        {value: 90},
        {value: 180},
        {value: 270},
      ],
      allTypes: [],
      editedItem: {},
      weekDays: [
        { id: 2, day: "Mån", isActive: false },
        { id: 3, day: "Tis", isActive: false },
        { id: 4, day: "Ons", isActive: false },
        { id: 5, day: "Tor", isActive: false },
        { id: 6, day: "Fre", isActive: false },
        { id: 7, day: "Lör", isActive: false },
        { id: 1, day: "Sön", isActive: false }
      ],
      isDisabled: false,
      isDisabledTime: false,
      step: 0,
      expId: 0,
      loading: 0,
      loading2: 0,
      loadingSave: null,
      tableItems: [],
      testDaysSaved: [],
      uniqueData: null,
      showAlert: false,
      valid: null,
      dialoglists: {},
      testExp: {},
      AdminApproved: "",
      detailsdialog: false,
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD HH:mm"
      },
      modelConfigTime: {
        type: "date",
        mask: "HH:mm"
      },
      location: [],
      marker: new mapboxgl.Marker({
        draggable: false,
        color: "purple",
      }),
      access_token:
        "pk.eyJ1IjoibWluZGZsb3dlciIsImEiOiJjbDBtMjBiNWsxMDdvM2tuc3FhanZ4cTh5In0.zld6Cvhb_W59V5XTLckfYw",
      startingPoint: [16.547054645517534, 59.610491130108215],
      map: null,
      coordinates: [],
      geocoder: {},
      rules: {
        required: value => !!value || "",
        min: v => v.length < 500 || "Max 500 bokstäver"
      }
    };
  },

  created() {
    this.getitemsid(this.id);
    this.getitems(this.$store.getters.id);
  },
  mounted() {
    this.createMap();
  },
 computed:{
  currentExp(){
    return this.editedItem.experiencetypeId
  },
  getProgress(){
    return config.onUploadProgress
  }
 },

  watch: {
    currentExp(oldExp, newExp){
      if(newExp){
      this.chip1 = false;
      this.editedItem.fileName = null;
      this.editedItem.fileSize = null;
      this.chip2 = false;
      // this.editedItem.fileName2 = null;
      // this.editedItem.fileSize2 = null;
      this.chip3 = false;
      this.editedItem.subtitles = undefined;
      }
    }
  },
  methods: {
    pushProp(showAlert) {
      router.push({name: 'Experiences', params: {showAlert: showAlert}, props: {showAlert: showAlert}})
    },
    preview(filename){
      // var encode = encodeURI(filename)
      var uri = 'https://hubblastorage.blob.core.windows.net/media/' + filename
      return uri;
    },

    checkRegionBeforeSave(justSave) {
      if (this.location[1] == " Västmanland"||this.location[1] == " Södermanland" ) {

        this.Save(justSave)

      }

      else {
        window.alert("Vänligen välj en plats inom Västmanland eller Södermanland");
      }
    },
    getitemsid(id) {
      if (this.id > 0) {
        HTTP.get("/experience/get/id/" + id).then(response => {
        this.editedItem = response.data;
        this.editedItem.activeOnWalk = false;
        this.coordinates = this.editedItem.coordinate.split(",");
        this.getValues();
        this.checkValidRegion();
      })}
      HTTP.get("/experience/getExpWalk").then((exp) =>{
        this.experiences = exp.data
      })
      HTTP.get("/experience/gettypes").then(response => {
        this.dialoglists = response.data;
        this.mapClick();
        this.checkValidRegion();
      });
    },
    async checkValidRegion() {
      const response = await axios.get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${this.coordinates[0]},${this.coordinates[1]}.json?types=place&access_token=${this.access_token}`
      );
      this.location = response.data.features[0].place_name.split(",");
    },
    mapSettings() {
      this.geocoder = new MapboxGeocoder({
        accessToken: this.access_token,
        enableHighAccuracy: true,
        mapboxgl: mapboxgl,
        marker: false,
        language: 'sv-SV',
        reverseGeocode: true
      });
      this.geocoder.on("result", (e) => {
        this.marker
        .setLngLat(e.result.center)
        .addTo(this.map);
        this.coordinates = Object.values(this.marker.getLngLat());
        this.checkValidRegion();
        this.marker.on("dragend", (e) => {
          this.coordinates = Object.values(e.target.getLngLat());
          this.editedItem.coordinate = this.coordinates.toString();
        });
      });
    },
    mapClick() {
      this.map.on("click", e => {
        this.marker.setLngLat(e.lngLat).addTo(this.map);
        this.coordinates = Object.values(this.marker.getLngLat());
        this.checkValidRegion();
        this.editedItem.coordinate = this.coordinates.toString();
        this.editedItem.countyName = this.location[0];
        this.editedItem.regionName = this.location[1];
      });
      // this.marker.setLngLat(this.coordinates).addTo(this.map);
    },
    async createMap() {
      mapboxgl.accessToken = this.access_token;
      this.map = new mapboxgl.Map({
        container: "map",
        style: "mapbox://styles/mapbox/streets-v11",
        center: this.startingPoint,
        zoom: 8
      });
      let geocoder = new MapboxGeocoder({
        accessToken: this.access_token,
        enableHighAccuracy: true,
        mapboxgl: mapboxgl,
        marker: false,
        reverseGeocode: true,
        language: 'sv-SV',
      });
      this.map.addControl(geocoder);
      geocoder.on("result", (e) => {
        this.marker
        .setLngLat(e.result.center)
        .addTo(this.map);
        this.coordinates = Object.values(this.marker.getLngLat());
        this.checkValidRegion();
        this.marker.on("dragend", (e) => {
          this.coordinates = Object.values(e.target.getLngLat());
          this.editedItem.coordinate = this.coordinates.toString();
        });
      });
    },

    ifDisabledTime() {
      if (this.isDisabledTime == false) {
        this.editedItem.weekDaysStartTime = null;
        this.editedItem.weekDaysEndTime = null;
      }
    },
    ifDisabled() {
      if (this.isDisabled == false) {
        this.resetValues();
        this.buttonActiveColor(this.weekDays);
      }
    },
    remove(day) {
      this.testDaysSaved.splice(this.testDaysSaved.indexOf(day.id), 1);
    },
    buttonActiveColor(day) {
      return day.isActive ? "primary" : "";
    },
    buttonActiveBool(day) {
      return (day.isActive = day.isActive ? false : true);
    },
    buttonActiveSendValue(day) {
      return day.isActive ? this.testDaysSaved.push(day.id) : this.remove(day);
    },
    checkForEmptyDates() {
      if (this.testDaysSaved == "" || this.testDaysSaved == []) {
        this.testDaysSaved = null;
        this.editedItem.weekDaysActive = null;
        // if(this.editedItem.weekDaysStartTime && this.editedItem.weekDaysEndTime == null || [] || "")
        //   this.editedItem.weekDaysStartTime = null;
        //   this.editedItem.weekDaysEndTime = null;
      } else {
        this.removeDuplicates();
      }
    },
    getValues() {
      this.testDaysSaved = [];
      if(this.editedItem.fileName != null){
          this.chip1 = true;
          this.loading = 100;
          if(this.editedItem.fileName2 != null){
            this.chip2 = true;
            this.loading = 100;
          }
          if (this.editedItem.subtitles != undefined) {
            this.chip3 = true;
        }
        }
      this.marker.setLngLat(this.coordinates).addTo(this.map);
      if (this.coordinates != []) {
        this.startingPoint = this.coordinates
      }
      for (let i = 0; i < this.weekDays.length; i++) {
        var index = this.weekDays[i];
        if (this.editedItem.weekDaysActive == null) {
          index.isActive = Boolean(false);
          this.isDisabled = Boolean(false);
        } else if (this.editedItem.weekDaysActive.includes(index.id)) {
          index.isActive = Boolean(true);
          this.isDisabled = Boolean(true);
          this.testDaysSaved.push(index.id);
        } else {
          index.isActive = Boolean(false);
        }
      }

      if (
        this.editedItem.weekDaysStartTime &&
        this.editedItem.weekDaysEndTime != null
      ) {
        this.isDisabledTime = Boolean(true);
      }
      this.editedItem.weekDaysActive = null;
      this.editedItem.weekDaysActive = new Set();
      this.testDaysSaved.forEach(day => {
        this.editedItem.weekDaysActive.add(day);
      });

    },
    removeDuplicates() {
      this.editedItem.weekDaysActive = [...new Set(this.testDaysSaved)].join();
    },
    resetValues() {
      for (let i = 0; i < this.weekDays.length; i++) {
        var index = this.weekDays[i];
        index.isActive = Boolean(false);
      }
      // this.marker.remove();
      this.testDaysSaved = [];
      this.editedItem.weekDaysStartTime == null;
      this.editedItem.weekDaysEndTime == null;
      // this.editedItem.coordinate == null;
      // this.coordinates = [];
    },
    onFileChange(e) {
      this.waitscreen = true;
      var fileName = document.getElementById("file-upload").value;
      var idxDot = fileName.lastIndexOf(".") + 1;
      var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      for (var i = 0; i < files.length; i++) {
        if (this.editedItem.experiencetypeId == null || 0) {
          this.$root.$okconfirm
          .open("","Du måste välja en Typ av Upplevelse innan du kan ladda upp en fil.")
          break;
        }
        else if(this.editedItem.experiencetypeId == 1 && extFile=="iosbundle" || extFile=="androidbundle"){this.createfile(files[i]); }
        else if(this.editedItem.experiencetypeId == 2 && extFile=="mp4"){this.createfile(files[i]);}
        else if(this.editedItem.experiencetypeId == 3 && extFile=="jpg" || extFile=="jpeg" || extFile=="png"){this.createfile(files[i]);}
        else if(this.editedItem.experiencetypeId == 4 && extFile=="jpg" || extFile=="jpeg" || extFile=="png"){this.createfile(files[i]);}
        else if(this.editedItem.experiencetypeId == 5 && extFile=="mp3"){this.createfile(files[i]);}
        else if(this.editedItem.experiencetypeId == 6 && extFile=="mp4"){this.createfile(files[i]);}
        else {
          this.$root.$okconfirm
          .open("","Filformatet du försökt ladda upp stöds ej, var vänlig stäng denna ruta och läs informationen längst ner för upplevelser och vandringar. Informationen finns längst ner i fotnoten av sidan.")
          this.loading = 0;
        }
      }

      // this.editedItem.fileName = null;
      e.target.value = null;
    },
    formatTime(string) {
      return (
        string.substring(0, 10) + " " + string.substring(11, string.length - 4)
      );
    },
    onFileChange2(e) {
      this.waitscreen = true;
      var fileName = document.getElementById("file-upload2").value;
      var idxDot = fileName.lastIndexOf(".") + 1;
      var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      for (var i = 0; i < files.length; i++) {
        if(this.editedItem.experiencetypeId == 2 && extFile=="srt"){this.createfile2(files[i]); }
        else if(this.editedItem.experiencetypeId == 1 && extFile=="iosbundle" || extFile=="androidbundle"){this.createfile2(files[i]); }
          else {
          this.$root.$okconfirm
          .open("","Välj rätt filformat för den upplevelsetypen du valt.")
          this.loading2 = 0;
        }
      }
      // this.editedItem.subtitles = null;
      // this.editedItem.fileName2 = null;
      e.target.value = null;
    },
    onFileChangeSubtitles(e) {
      this.waitscreen = true;
      var fileName = document.getElementById("file-uploadSubtitles").value;
      var idxDot = fileName.lastIndexOf(".") + 1;
      var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      for (var i = 0; i < files.length; i++) {
        if(this.editedItem.experiencetypeId == 2 && extFile=="srt"){this.createfileSubtitles(files[i]); }
          else {
          this.$root.$okconfirm
          .open("","Välj rätt filformat för den upplevelsetypen du valt.")
          this.loading = 0;
        }
      }
      this.editedItem.subtitles = null;
      this.editedItem.fileName2 = null;
      e.target.value = null;
    },
    showstatusdialog(item) {
      this.selecteditem = item;
      // this.statusdialog = true
    },
    getStatus(item) {
      switch (item) {
        case false:
          return "red";
        case null:
          return "orange";
        case true:
          return "green";
      }
    },
    getStatustext(item) {
      switch (item) {
        case false:
          return "Ej godkänd";
        case null:
          return "Pågående";
        case true:
          return "Publicerad";
      }
    },
    setStatus(item, status) {
      if (status === 1) {
        item.isApproved = null;
      } else if (status === 2) {
        item.isApproved = true;
      }
      HTTP.post("/experience/approvedexp", {
        id: item.id,
        creatorId: item.creatorId,
        isApproved: item.isApproved
      })
        .then(response => {
          this.AdminApproved = response.data;
        })
        .catch(e => {
          this.$store.dispatch(
            "showError",
            "Error when setting status: " + e.message
          );
        });
    },
    deniedExperience(item, status) {
      if (status === 0) {
        this.$root.$deniedmessage
          .open(
            "Du kommer nu neka denna uppelevelse",
            "Välj ett alternativ eller skriv ett meddelande till skaparen",
            { color: "red" }
          )
          .then(response => {
            if (response) {
              item.isApproved = false;
              HTTP.post("/experience/approvedexp", {
                id: item.id,
                creatorId: item.creatorId,
                isApproved: item.isApproved,
                expDeniedMessage: response[0],
                expDeniedOption: response[2]
              });
            }
          })
          .catch(e => {
            this.$store.dispatch(
              "showError",
              "Error when setting status: " + e.message
            );
          });
      }
    },
    createfile(file) {
      var reader = new FileReader();
      reader.onload = e => {
        this.localfile = {
          filedata: btoa(e.target.result),
          filename: file.name,
          filesize: file.size
        };
          this.saveattachement();
          file = null;
      };
      reader.readAsBinaryString(file);
    },
    createfile2(file) {
      var reader = new FileReader();
      reader.onload = e => {
        if(this.editedItem.experiencetypeId == 2 && this.editedItem.fileName != null){
          this.localfile2 = {
          filedata2: btoa(e.target.result),
          filename2: file.name,
          filesize2: file.size,
          subtitles: file.name,
        };
          this.saveattachementSub();
        }
        else{
          this.localfile2 = {
          filedata2: btoa(e.target.result),
          filename2: file.name,
          filesize2: file.size
        };
          this.saveattachement2();
        }

        file = null;
      };
      reader.readAsBinaryString(file);
    },
    createfileSubtitles(file) {
      var reader = new FileReader();
      reader.onload = e => {
        if(this.editedItem.experiencetypeId == 2 && this.editedItem.subtitles != null){
          this.localfile3 = {
          filedata2: btoa(e.target.result),
          filename2: file.name,
          filesize2: file.size,
          subtitles: file.name,
        };
        // reader.readAsBinaryString(file);
        this.saveattachementSub(this.localfile3);
      }
      file = null;
      };
      reader.readAsBinaryString(file);
    },
    saveattachement() {
      var self = this;
      HTTP.post("/experience/savelocalfile", self.localfile, {
      onUploadProgress: progressEvent => {
        this.loading = 0;
      var progress = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
    if (config.onProgress)
        config.onProgress(progress);
        console.log(progress);
        this.loading = progress;
  }
})
        .then(() => {
            self.editedItem.fileName = self.localfile.filename;
            self.editedItem.fileSize = self.localfile.filesize;
            this.chip1 = true;
            // this.loading = null;

        })
        .catch(e => {
          self.$store.dispatch("showError", "Fel vid sparning: " + e.response.data);
        });
        this.loading = null;
    },
    saveattachementSub() {
      var self = this;
      HTTP.post("/experience/savelocalfile", this.localfile2, {
      Headers: {'Content-Type': 'multipart/form-data'},
      onUploadProgress: progressEvent => {
      var progress = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
    if (config.onProgress)
        config.onProgress(progress);
        console.log(progress);
        this.loading2 = progress;
  }
})
        .then(() => {
            this.editedItem.subtitles = self.localfile2.subtitles;
            this.chip3 = true;
            // this.loading2 = null;
        })
        .catch(e => {
          self.$store.dispatch("showError", "Fel vid sparning: " + e.response.data);
        });
      },
    saveattachement2() {
      var self = this;
      this.loading2 = 0;
      HTTP.post("/experience/savelocalfile/", self.localfile2, {
      Headers: {'Content-Type': 'multipart/form-data'},
      onUploadProgress: progressEvent => {
      var progress = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
    if (config.onProgress)
        config.onProgress(progress);
        console.log(progress);
        this.loading2 = progress;
  }
})
        .then(() => {
          self.editedItem.fileName2 = self.localfile2.filename2;
          self.editedItem.fileSize2 = self.localfile2.filesize2;
          this.chip2 = true;
        })
        .catch(e => {
          self.$store.dispatch("showError", "Error when saving: " + e.response.data);
        });
    },
    deleteattachement(){
      this.$root.$confirm
      .open("", "Är du säker att du vill ta bort filen?")
      .then(response => {
        if(response){
          this.chip1 = false;
      this.editedItem.fileName = null;
      this.editedItem.fileSize = null;
        }
      })
    },
    deleteattachement2(){
      this.$root.$confirm
      .open("", "Är du säker att du vill ta bort filen?")
      .then(response => {
        if(response){

        this.chip2 = false;
      this.editedItem.fileName2 = null;
      this.editedItem.fileSize2 = null;
        }
      })
    },
    deleteattachement3(){
      this.$root.$confirm
      .open("", "Är du säker att du vill ta bort filen?")
      .then(response => {
        if(response){
          this.chip3 = false;
      this.editedItem.subtitles = undefined;
        }
      })
    },
    getitems(userId) {
      HTTP.get("/experience/get/" + userId).then(response => {
        this.tableItems = response.data;
        var tableKeys = Object.keys(this.tableItems);
        for (let index = 0; index < tableKeys.length; index++) {
            const element = this.tableItems[index];
            this.savedCoords = JSON.parse("[" + element.coordinate + "]")
            if (element.id != this.id) {
              new mapboxgl.Marker({
              draggable: false,
              color: "rgba(186, 181, 182, 0.75)",
              clickTolerance: 1})
              .setLngLat(this.savedCoords)
              .addTo(this.map);
              this.savedCoords = null;
            }
        }
        this.activeWalkSearcher();
        });
    },
    newitem() {
      this.editItem();
      this.mapSettings();
    },
    editItem() {
      this.editedItem = Object.assign({}, this.testExp);
      HTTP.get("/experience/gettypes").then(response => {
        this.dialoglists = response.data;
      });
    },
    deleteItem() {
      const item = this.editedItem;
      this.dialog = false;
      var self = this;
      if(item.activeOnWalk == true){
        this.$root.$confirm
        .open("Denna upplevelse finns i en Vandring", "Är du säker?")
        .then(response => {
          if (response) {
            HTTP.post("/experience/delete", { id: item.id }).then(() => {
              self.tableItems.splice(self.tableItems.indexOf(item), 1);
              this.getitems(this.$store.getters.id);
              router.push({name: 'Experiences',  props: this.showAlert})
            });
          }
        });
      }
      else{
        this.$root.$confirm
        .open("Radera upplevelse", "Är du säker?")
        .then(response => {
          if (response) {
            HTTP.post("/experience/delete", { id: item.id }).then(() => {
              self.tableItems.splice(self.tableItems.indexOf(item), 1);
              this.getitems(this.$store.getters.id);
              router.push({name: 'Experiences',  props: this.showAlert})
            });
          }
        });
      }

    },
    activeWalkSearcher(){
      const expKeys = Object.keys(this.experiences);
        const tableKeys = Object.keys(this.tableItems);
        this.tableItems.activeOnWalk = null;
        for (let key_Index = 0; key_Index < expKeys.length; key_Index++) {
          for (let tbl_Index = 0; tbl_Index < tableKeys.length; tbl_Index++) {
            const tbl = this.tableItems[tbl_Index];
            const exp = this.experiences[key_Index];
          if(tbl.id === exp.experienceId){
            tbl.activeOnWalk = true
            if(exp.experienceId == this.editedItem.id){
              this.editedItem.activeOnWalk = true;
            }
          }
          else{
            tbl.activeOnWalk = false;
          }
          }
      }
    },
    pictureurl(picturename) {
      // eslint-disable-next-line
      return process.env.VUE_APP_APIURL + "/shared/bilder/" + picturename;
    },
    Save(justSave) {
      this.$refs.form.validate();
      if (!this.valid) {
        this.$root.$okconfirm.open(
          "",
          "Vänligen fyll i de rödmarkerade fälten i formuläret",
          { color: "red" }
        );
        return;
      }
      this.loadingSave = true;
      var self = this;
      self.editedItem.justSave = justSave;
      self.editedItem.countyName = this.location[0];
      self.editedItem.regionName = this.location[1];
            // eslint-disable-next-line no-console
       HTTP.post("/experience/save", this.editedItem)
        .then(response => {
                      // eslint-disable-next-line no-console
        if (response.status === 204 || response.status === 200) {
            router.push({name: 'Experiences',  props: this.showAlert})
          }
          if (this.id > 0) {
            // var editedIndex = self.tableItems.findIndex(
            //   q => q.id === self.editedItem.id
            // );

            if (self.editedItem.isApproved) {
              this.$root.$confirm.open(
                "Uppdatering av upplevelsen kommer att avpublicera din upplevelse i väntan på nytt godkännande.",
                "Är du säker?",
                { color: "red" }
              );
            }
            this.editedItem.name = self.editedItem.name;
            this.editedItem.description =
              self.editedItem.description;
            this.editedItem.coordinate =
              self.editedItem.coordinate;
            this.editedItem.experiencetypeId =
              self.editedItem.experiencetypeId;
            this.editedItem.badgeId = self.editedItem.badgeId;
            this.editedItem.countyId = self.editedItem.countyId;
            this.editedItem.fileName = response.data.fileName;
            this.editedItem.fileName2 = response.data.fileName2;
            this.editedItem.fileSize = response.data.fileSize;
            this.editedItem.fileSize2 = response.data.fileSize2;
            this.editedItem.startTime = response.data.startTime;
            this.editedItem.endTime = response.data.endTime;
            this.editedItem.weekDaysActive =
              self.editedItem.weekDaysActive;
            this.editedItem.weekDaysStartTime =
              self.editedItem.weekDaysStartTime;
            this.editedItem.weekDaysEndTime =
              self.editedItem.weekDaysEndTime;
            this.editedItem.isApproved = null;
          } else {
            self.editedItem.id = response.data;
            this.editedItem.push(this.editedItem);
          }
          this.getitems(this.$store.getters.id);
          this.loadingSave = false;
          this.tableItems.push(this.editedItem);
          self.dialog = false;
          this.$refs.form.resetValidation();
          if (!justSave) {
            this.showAlert = true;
            this.pushProp(true);
          }
          setTimeout(() => {
            this.showAlert = false;
          }, 7000);
          // window.location.replace("/experiences")
        })

        .catch(e => {
          this.loadingSave = false;
          self.$store.dispatch(
            "showError",
            "Fel vid sparning: " + e.response.data
          );
        });
        // window.location.replace("/experiences")
    },
    cancel() {
      this.dialog = false;
      this.$refs.form.resetValidation();
      router.push({name: 'Experiences', props: this.showAlert = true})
      // window.location.replace("/experiences")
    }
  },
  components: { Appbar }
};
</script>

<style scoped>

input {
  display: none;
}
.buttoncreate {
  padding: 8px 12px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  outline: none;
  color: #fff;
  background: #69c5e4;
  border: none;
  border-radius: 6px;
  box-shadow: 0 6px #34aacb;
}

.buttoncreate:active {
  box-shadow: 0 5px #69c5e4;
  transform: translateY(4px);
}

.closeexp {
  padding: 5px 16px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  outline: none;
  color: #fff;
  background: #d2d2d2;
  border: none;
  border-radius: 6px;
  box-shadow: 0 6px #a3a3a4;
}

.closeexp:active {
  box-shadow: 0 5px #d2d2d2;
  transform: translateY(4px);
}

.saveexp {
  padding: 5px 16px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  outline: none;
  color: #fff;
  background: #6abf62;
  border: none;
  border-radius: 6px;
  box-shadow: 0 6px #4ca645;
}

.saveexp:active {
  box-shadow: 0 5px #6abf62;
  transform: translateY(4px);
}

.uploadbuttoncreator {
  padding: 10px 16px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  outline: none;
  color: #fff;
  background: orange;
  border: none;
  border-radius: 6px;
  box-shadow: 0 6px rgb(214, 141, 4);
}
.chip{
  width: 150px;
}
.progressStyle {
  padding-left:60%;
  position: absolute;
  z-index: 2;
  top: 21vh;
  left: 25vh;
}
.saveexp:active {
  box-shadow: 0 5px orange;
  transform: translateY(4px);
}

/* Info icon */

.relativeDiv {
  position: relative;
}

.help-tip {
  transform: translate(-50%, -50%);
  margin: 12px 15px;
  text-align: center;
  border: 2px solid #444;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 12px;
  line-height: 21px;
  cursor: default;
  z-index: 1;
}

.help-tip:before {
  content: "i";
  font-family: sans-serif;
  font-weight: normal;
  color: rgb(17, 17, 17);
}

.help-tip:hover p {
  display: block;
  transform-origin: -100% 0%;
  -webkit-animation: fadeIn 0.3s ease;
  animation: fadeIn 0.3s ease;
}

.help-tip p {
  display: none;
  font-family: sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  text-align: left;
  background-color: #ffffff;
  padding: 12px 16px;
  width: 300px;
  height: auto;
  position: absolute;
  top: -370px;
  left: 50%;
  transform: translate(-50%, 5%);
  border-radius: 3px;
  box-shadow: 0 0px 20px 0 rgba(0, 0, 0, 0.1);
  color: #37393d;
  font-size: 12px;
  line-height: 18px;
  z-index: 99;
}
/* .label-color /deep/ label {
  color: red;
} */

.help-tip p a {
  color: #067df7;
  text-decoration: none;
}

.help-tip p a:hover {
  text-decoration: underline;
}

.uploadbutton {
  border-radius: 3px;
  border: none;
  background-color: rgb(245,245,245);
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.2), 0 1.5px 5px 0 rgba(0,0,0,0.19);
}

/* Prevents the tooltip from being hidden */
.help-tip p:after {
  width: 10px;
  height: 40px;
  content: "";
  position: absolute;
  top: -40px;
  left: 0;
}

.approvedAndCreatedBy {
  text-align: right;
  padding-right: 10px;
  margin-bottom: 0px;
}

.weekdaysBtn {
  width: 50px;
  height: 25px;
  font-size: 12px;
}

.main {
  padding: 45px 50px;
}
.flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.map-holder {
  width: 65%;
}
#map {
  height: 40vh;
}
.text-padding {
  padding-bottom:5px;
}

/* CSS animation */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100%;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100%;
  }
}
</style>
