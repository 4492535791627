<template>
  <div>
    <v-card class="appbarbackground" v-if="checkUserRole()">
      <div class="row justify-end align-center">
        <div class="col-md-4">
          <div class="d-flex justify-start">
            <img src="./../assets/Hubblaloggo.png" style="width: 300px;" class="ml-8">
          </div>
        </div>
        <div class="col-md-4">
          <div class="d-flex justify-center mt-10">
            <v-btn class="adminbutton" to="/creators"><v-icon class="pa-2">mdi mdi-account-circle</v-icon>Skapare</v-btn>
            <v-btn class="adminbutton" to="/statistics"><v-icon class="pa-2">mdi mdi-chart-line</v-icon>Statistik</v-btn>
            <v-btn class="adminbutton" to="/experiences"><v-icon class="pa-2">mdi mdi-star</v-icon>Upplevelser</v-btn>
          </div>
        </div>
        <div class="col-md-4">
          <div class="d-flex justify-end mr-5 mt-10">           
            <v-btn class="adminbutton" to="/logout"><v-icon class="pa-2">mdi-logout</v-icon>Logga ut</v-btn>
          </div>
        </div>
      </div>
    </v-card>
        <v-card class="appbarbackground" v-else-if="checkSuperUserRole()">
      <div class="row justify-end align-center">
        <div class="col-md-4">
          <div class="d-flex justify-start">
            <img src="./../assets/Hubblaloggo.png" style="width: 300px;" class="ml-8">
          </div>
        </div>
        <div class="col-md-4">
          <div class="d-flex justify-center mt-10">
            <!-- <v-btn class="adminbutton" to="/users"><v-icon class="pa-2">mdi mdi-account</v-icon>Användare</v-btn>  -->
            <v-btn class="adminbutton" to="/creators"><v-icon class="pa-2">mdi mdi-account-circle</v-icon>Skapare</v-btn>
            <v-btn class="adminbutton" to="/statistics"><v-icon class="pa-2">mdi mdi-chart-line</v-icon>Statistik</v-btn>
            <v-btn class="adminbutton" to="/experiences"><v-icon class="pa-2">mdi mdi-star</v-icon>Upplevelser</v-btn>
            <v-btn class="adminbutton" to="/administrators"><v-icon class="pa-2">mdi-account-key</v-icon> Administratörer</v-btn>
          </div>
        </div>
        <div class="col-md-4">
          <div class="d-flex justify-end mr-5 mt-10">           
            <v-btn class="adminbutton" to="/logout"><v-icon class="pa-2">mdi-logout</v-icon>Logga ut</v-btn>
          </div>
        </div>
      </div>
    </v-card>
    <v-card class="appbarbackgroundcreator" v-else-if="checkUserRoleCreator()">
      <div class="row justify-end align-center">
        <div class="col-md-4">
          <div class="d-flex justify-start">
             <img src="./../assets/Hubblaloggo.png" style="width: 300px" class="ml-8">
          </div>
        </div>
        <div class="col-md-4">
          <div class="d-flex justify-center mt-10" vi-if="checkUserRole()">
          </div>
        </div>
        <div class="col-md-4">
          <div class="d-flex justify-end mr-5 mt-10">           
            <a href="/logout" style="text-decoration: none; color: #fff;"><button type="submit" class="buttoncreate" ><v-icon style="padding: 3px; color: #fff;">mdi-logout</v-icon>Logga ut</button></a>
          </div>
        </div>
      </div>
    </v-card>
    <v-card class="appbarbackgroundcreator" v-else>
      <div class="row justify-end align-center">
        <div class="col-md-4">
          <div class="d-flex justify-start">
            <img src="./../assets/Hubblaloggo.png" style="width: 300px" class="ml-8">
          </div>
        </div>
        <div class="col-md-4">
          <div class="d-flex justify-center mt-10">
            <!-- Kom ihåg att inte skicka med användar data i backend -->
            <!-- <v-btn class="adminbutton" to="/home"><v-icon class="pa-2"></v-icon>Hem</v-btn> -->
            <!-- <v-btn class="adminbutton" to=""><v-icon class="pa-2"></v-icon>Integratetpolicy</v-btn> -->
          </div>
        </div>
        <div class="col-md-4">
          <div class="d-flex justify-end mr-5 mt-10">   
          <v-btn class="adminbutton" to="/Login"><v-icon class="pa-1"></v-icon>Logga in</v-btn> 
            <!-- <v-btn class="adminbutton" to="/login"><v-icon class="pa-2">mdi-logout</v-icon>Logga in / Skapa konto</v-btn> -->
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>
<script>



export default {
  data () {
    return {  
      userRole: "0",
      creatorRole: "1",
      playerRole: "2",
      SuperUser: "3",
      renderComponent: true,
    }
  },

  methods: {
    checkUserRole() {
        if(this.$store.getters.usertype === this.userRole) {
        return true
        }
    },
    checkSuperUserRole() {
      if(this.$store.getters.usertype === this.SuperUser) {
        return true
      }
    },
    checkUserRoleCreator() {
        if(this.$store.getters.usertype === this.creatorRole || this.$store.getters.usertype === this.playerRole) {
          return true
        }
    },
  },
  computed: {
    // userType: this.$store.getters.usertype
  }
}

// mdi-account-key
</script>
<style lang="css" scoped>
    .appbarbackgroundcreator {
        background-color: #ffffff;
    }

.buttoncreate {
  padding: 8px 12px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  outline: none;
  color: #fff;
  background: #DB6352;
  border: none;
  border-radius: 6px;
  box-shadow: 0 6px #D44121;
}

.buttoncreate:active {
  box-shadow: 0 5px #DB6352;
  transform: translateY(4px);
}

.experiencesbtn {
  padding: 8px 25px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  outline: none;
  color: #fff;
  background: #69c5e4;
  border: none;
  border-radius: 6px;
}

.loginbtn {
  padding: 8px 25px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  outline: none;
  color: #fff;
  background: #69c5e4;
  border: none;
  border-radius: 6px;
  box-shadow: 0 6px #34AACB;
  background-color: #69c5e4 !important;
}

.loginbtn:active {
  box-shadow: 0 5px #69c5e4;
  transform: translateY(4px);
  background-color: #69c5e4 !important;
}

</style>