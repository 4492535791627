<template>
    <v-dialog v-model="dialog" :max-width="options.width" :style="{ zIndex: options.zIndex }" @keydown.esc="cancel">
    <v-card>
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text v-show="!!message">{{ message }}</v-card-text>
      <div class="container">
        <!-- LÄGG PÅ FÖR ALTERNATIV -->
          <!-- <v-radio-group py-3
              column
              v-model="checkboxOptions"
            >
              <v-radio
                label="Din fil innehåller olläpligt innehåll"
                color="secondary"
                value="Din fil innehåller olläpligt innehåll"
              ></v-radio>
              <v-radio
                label="Filen du har laddat upp funkar inte"
                color="secondary"
                value="Filen du har laddat upp funkar inte"
                input-value="true"
              ></v-radio>
              <v-radio
                label="Den plats du försöker ange för din upplevelse finns inte"
                color="secondary"
                value="Den plats du försöker ange för din upplevelse finns inte"
              ></v-radio>
            </v-radio-group> -->
          <v-textarea
          outlined
          name="input-7-4"
          label="Meddelande"
          placeholder="Vad vill du skriva till skaparen"
          v-model="customMessage"
        ></v-textarea>
      </div>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn color="grey" text @click.native="cancel">Avbryt</v-btn>
        <v-btn color="primary darken-1" text @click.native="agree">Neka upplevelse</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    customMessage: '',
    checkboxOptions: [],
    selected: [],
    title: null,
    options: {
      color: 'primary',
      width: 490,
      zIndex: 200
    }
  }),
  methods: {
    open(title, message, options) {
      this.dialog = true
      this.title = title
      this.message = message
      this.options = Object.assign(this.options, options)
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    agree() {
      let deniedmessages = true
      // LÄGG PÅ FÖR ALTERNATIV
     // this.resolve([this.customMessage, deniedmessages, this.checkboxOptions])
     this.resolve([this.customMessage, deniedmessages])
      this.dialog = false
    },
    cancel() {
      this.resolve(false)
      this.dialog = false
    }
  }
}
</script>
    
  
