<template>
  <v-main>
          <div style="text-align: right;">
        <v-btn style="margin: 17px" to="/home" class="closeexp adminlargebutton"
      >Hem</v-btn
    >
          </div>
          <div class="text-center">
        <img src="./../assets/Hubblaloggo.png" class="headimg"/>
      </div>
    <v-container style="max-width: 600px;">
      <div style="text-align: center;">
                      <v-btn
                        class="integrate" text
                        >Logga in med dina spelaruppgifter</v-btn
                      >
                      </div>
            <v-card class="px-4 rounded-lg">
              <v-card-text>
                <v-form>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="userinfo.email"
                        label="E-mail"
                        name="login"
                        prepend-icon="mdi-account"
                        type="text"
                        v-on:keyup="checkreturn"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="userinfo.password"
                        id="password"
                        label="Lösenord"
                        name="password"
                        prepend-icon="mdi-lock"
                        type="password"
                        v-on:keyup="checkreturn"
                      ></v-text-field>
                    </v-col>
                    <v-col class="d-flex" cols="12" sm="6" xsm="12"> </v-col>
                    <v-spacer></v-spacer>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        class="loginbtncr adminlargebutton"
                        @click="login()"
                        >Bli skapare</v-btn
                      >
                    </v-card-actions>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import { HTTP } from "@/plugins/backendAPI";

export default {
  name: "LoginPlayer",
  data() {
    return {
      userinfo: {
        email: "",
        password: "",
        usertype: "",
      },
      info: "",
      loading: false,
      rules: {
        required: (value) => !!value || "Required.",
        min: (value) => value.length >= 4 || "Minst 4 tecken",
      },
      showpassword: false,
    };
  },
  methods: {
    checkreturn(e) {
      if (e.keyCode === 13) {
        this.login();
      }
    },
    login() {
      this.loading = true;
      var self = this;
      var params =
        "username=" +
        this.userinfo.email +
        "&password=" +
        encodeURIComponent(this.userinfo.password);
      delete HTTP.defaults.headers.common["Authorization"];
      // eslint-disable-next-line
      axios({
        method: "post",
        /* eslint-disable */
        url: process.env.VUE_APP_APIURL + "/token/login/admin ",
        /* eslint-enable */
        data: params,
        headers: {
          accept: "application/json",
          "content-type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => {
          self.loginSuccessful(response);
        })
        .catch((ex) => {
          self.loginFailed(ex);
        });
    },
    loginSuccessful(req) {
      if (!req.data.bearer || req.data.usertype == 0 || req.data.usertype == 1 || req.data.usertype == 3) {
        this.loginFailed();
        return;
      }
      if (HTTP.defaults.headers.common["Authorization"] === undefined) {
        HTTP.defaults.headers.common["Authorization"] =
          "Bearer " + req.data.bearer;
      }
      HTTP.post("/account/getkund", { email: this.userinfo.email })
        .then(() => {
          this.$store.dispatch("saveAuthorization", {
            id: req.data.id,
            username: this.userinfo.email,
            usertype: req.data.usertype,
            isauthorized: true,
            bearertoken: req.data.bearer,
          });
          this.$router.push({ path: "/registercreator" });
          this.loading = false;
        })
        .catch((e) => {
          this.$store.dispatch("showError", e.message);
          this.loading = false;
        });
    },
    loginFailed() {
      this.loading = false;
      this.$store.dispatch("showError", "Kontrollera uppgifterna");
      this.$store.dispatch("deleteAuthorization");
    },
    recoverpassword() {
      this.$router.push({ path: "/recoverpassword" });
    },
  },
};
</script>

<style lang="css">
.loginbtncr {
  padding: 10px 16px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  outline: none;
  color: #fff;
  background: #69c5e4;
  background-color: #69c5e4 !important;
  border: none;
  border-radius: 6px;
  box-shadow: 0 6px #34aacb;
}

.loginbtncr:active {
  box-shadow: 0 5px #69c5e4;
  transform: translateY(4px);
}
</style>

