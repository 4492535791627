<template>
    <v-main>
      <div class="py-8 text-center">
        <img class="headimg" src="./../assets/Hubblaloggo.png" />
      </div>
      <v-container>
        <v-layout align-center justify-center mt-5>
          <v-flex xs12 sm8 md4>
            <v-card class="elevation-12">
              <v-card-text class="">
                <v-form v-model="valid" ref="form">
                  <v-text-field :disabled="passwordsent" required v-model="password1" id="password" name="password" label="Nytt lösen" type="password" ></v-text-field>
                  <v-text-field :disabled="passwordsent" required v-model="password2" id="password" name="password" label="Ange lösenord igen" type="password"></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-btn v-if="passwordsent" color="primary" href="/home">Hem</v-btn>
                <v-spacer></v-spacer>
                <v-btn :disabled="passwordsent" color="primary" @click="changepassword()">Spara nytt lösenord</v-btn>
              </v-card-actions>
              <v-alert v-if="info" type="success">{{info}}</v-alert>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
</template>





<script>
import { HTTP } from '@/plugins/backendAPI'

export default {
  props: ['guid'],
  data () {
    return {
      valid: true,
      loading: false,
      password1: null,
      password2: null,
      info: null,
      passwordsent: false
    }
  },
  created () {
    HTTP.get('/account/validateguid/' + this.guid).catch(e => {
        this.snackbartexterror = e.response.data
        this.showsnackbarerror = true
      })
  },
  methods: {
    changepassword () {
      if (this.$refs.form.validate() && this.password1 !== null && this.password1 === this.password2) {
        this.loading = true
        HTTP.post('/account/changepassword', {password: this.password1, guid: this.guid}).then(() => {
            this.info = 'Tack!'
            this.showsnackbar = true
            this.passwordsent = true
            
          }).catch(e => {
            this.$store.dispatch('showError', e.response.data)
          }).then(() => {
          this.loading = false
          })
      } else {
        this.$store.dispatch('showError', 'Ange samma lösenord i båda fälten')
      }
    }
  }
}

</script>

<style lang="css" scoped>

</style>
