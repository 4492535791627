<template>
  <div class="main">
    <div class="flex">
      <!-- Map Display here -->
      <div class="map-holder">
        <div id="map">
          <link
          href="https://api.tiles.mapbox.com/mapbox-gl-js/v0.53.0/mapbox-gl.css"
          rel="stylesheet"
      />
        </div>
      </div>

      <!-- Coordinates Display here -->
      <div class="dislpay-arena">
        <div class="coordinates-header">
          <h3>Current Coordinates</h3>
          <p>Latitude: {{ center[0] }}</p>
          <p>Longitude: {{ center[1] }}</p>
          <p>Coordinates Lat: {{coordinates[0]}}</p>
          <p>Coordinates Long: {{coordinates[1]}}</p>
        </div>

        <div class="coordinates-header">
          <h3>Current Location</h3>

          <div class="form-group">
            <input
              type="text"
              class="location-control"
             :value="location"
              readonly
            />
            <button type="button" class="copy-btn" @click="copyLocation">
              Copy
            </button>
          </div>

          <button
            type="button"
            :disabled="loading"
            :class="{ disabled: loading}"
            class="location-btn"
            @click="getLocation"
          >
            Get Location
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mapboxgl from "mapbox-gl";
import axios from "axios";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
export default {
  data() {
    return {
      loading: false,
      location: "",
      access_token: 'pk.eyJ1Ijoicm9iaW5taW5kZmxvd2VyIiwiYSI6ImNsOGlrYjI0cDByeW8zd21yNTh3anB2aHMifQ.LLsGVAdykCjHpF2R-b99BA',
      center: [16.546368, 59.611099],
      map: {},
      coordinates: [0,0]
    };
  },
  mounted() {
    this.createMap();
  },
  methods: {
    async createMap() {
      const marker = new mapboxgl.Marker({ 
          draggable: true,
          color: "#0000FF",
          })
      try {
        mapboxgl.accessToken = this.access_token;
        this.map = new mapboxgl.Map({
          container: "map",
          style: "mapbox://styles/mapbox/streets-v11",
          center: this.center,
          zoom: 11,
        });
        let geocoder =  new MapboxGeocoder({
            accessToken: this.access_token,
            reverseGeocode:true,
            enableHighAccuracy: true,
            mapboxgl: mapboxgl,
            marker: false,
          }); 
        this.map.addControl(geocoder);
        geocoder.on("result", (e) => {
            marker
            .setLngLat(e.result.center)
            .addTo(this.map);
          this.center = e.result.center;
          marker.on("dragend", (e) => {
            this.center = Object.values(e.target.getLngLat());
          });
        });

      } catch (err) {
        this.console.log("map error", err);
      }
      this.map.on('click', (e) => {
            marker
            .setLngLat(e.lngLat)
            .addTo(this.map);
            this.coordinates = Object.values(marker.getLngLat());
          //   marker.on("dragend", (x) => {
          //   this.coordinates = Object.values(x.getLngLat());
          // })
      })
          // alert(`A click event has occurred at ${e.lngLat}`);
         // });
    },
    async getLocation() {
      try {
        this.loading = true;
        const response = await axios.get(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${this.coordinates[0]},${this.coordinates[1]}.json?types=place&access_token=${this.access_token}`
        );
        this.loading = false;
        this.location = response.data.features[0].place_name;
        this.coordinates = this.location
      } catch (err) {
        this.loading = false;
        this.console.log(err);
      }
    },
    copyLocation() {
      if (this.location) {
        navigator.clipboard.writeText(this.location);
        alert("Location Copied")
      }
      return;
    }
  }}
</script>
<style scoped>
  .main {
    padding: 45px 50px;
  }
  .flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .map-holder {
    width: 65%;
  }
  #map {
    height: 60vh;
  }
  .dislpay-arena {
    background: #ffffff;
    box-shadow: 0px -3px 10px rgba(0, 58, 78, 0.1);
    border-radius: 5px;
    padding: 20px 30px;
    width: 25%;
  }
  .coordinates-header {
    margin-bottom: 50px;
  }
  .coordinates-header h3 {
    color: #1f2a53;
    font-weight: 600;
  }
  .coordinates-header p {
    color: rgba(13, 16, 27, 0.75);
    font-weight: 600;
    font-size: 0.875rem;
  }
  .form-group {
    position: relative;
  }
  .location-control {
    height: 30px;
    background: #ffffff;
    border: 1px solid rgba(31, 42, 83, 0.25);
    box-shadow: 0px 0px 10px rgba(73, 165, 198, 0.1);
    border-radius: 4px;
    padding: 0px 10px;
    width: 90%;
  }
  .location-control:focus {
    outline: none;
  }
  .location-btn {
    margin-top: 15px;
    padding: 10px 15px;
    background: #d80739;
    box-shadow: 0px 4px 10px rgba(73, 165, 198, 0.1);
    border-radius: 5px;
    border: 0;
    cursor: pointer;
    color: #ffffff;
    font-size: 0.875rem;
    font-weight: 600;
  }
  .location-btn:focus {
    outline: none;
  }
  .disabled {
    background: #db7990;
    cursor: not-allowed;
  }
  .copy-btn {
    background: #f4f6f8 0% 0% no-repeat padding-box;
    border: 1px solid #f4f6f8;
    border-radius: 0px 3px 3px 0px;
    position: absolute;
    color: #5171ef;
    font-size: 0.875rem;
    font-weight: 500;
    height: 30px;
    padding: 0px 10px;
    cursor: pointer;
    right: 3.5%;
    top: 5%;
  }
  .copy-btn:focus {
    outline: none;
  }
  </style>