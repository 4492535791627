<template>
  <div>
    <appbar></appbar>
    <v-row justify="center" style="z-index: 10;">
      <v-col md="2" sm="12">
        <v-menu
          v-model="menustart"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="datestart"
              label="Startdatum"
              class="ml-4"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            @input="selectdate()"
            type="month"
            v-model="datestart"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="2">
        <v-menu
          v-model="menuend"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateend"
              label="Slutdatum"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            @input="selectdate()"
            type="month"
            v-model="dateend"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="1">
        <v-btn text @click="clearfilter()" class="mt-3" width="30px"
          >Rensa</v-btn
        >
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col md="4" sm="12">
        <div id="chart">
          <apexchart
            type="line"
            height="350"
            :options="chartOptions"
            :series="series"
          ></apexchart>
        </div>
      </v-col>
      <v-col md="4" sm="12">
        <div id="chart">
          <apexchart
            type="line"
            height="350"
            :options="chartOptions2"
            :series="series2"
          ></apexchart>
        </div>
      </v-col>
    </v-row>
    <template>
      <v-row justify="center" class="mt-5">
        <v-col md="4" sm="12">
        <p><strong>Upplevelsebesök</strong></p>
          <v-data-table
            :headers="experiencevisits"
            :items="experienceVisitsData"
            :items-per-page="20"
            :footer-props="footerProps"
            class="elevation-1"
          >
        </v-data-table>
        </v-col>
          <v-col md="4" sm="12">
            <p><strong>Upplevelser</strong></p>
          <v-data-table
            :headers="createdexperience"
            :items="statsdata"
            :items-per-page="20"
            :footer-props="footerProps"
            class="elevation-1"
          >
            <template v-slot:top> </template>
          </v-data-table>
        </v-col>
      </v-row>
    </template>
  </div>
</template>
<script>
import { HTTP } from "@/plugins/backendAPI";

export default {
  name: "models",
  data() {
    return {
      datestart: null,
      dateend: null,
      menustart: false,
      menuend: false,
      headers: [
        { text: "Upplevelsenamn", align: "left", value: "" },
        { text: "Kommun", align: "left", value: "countyName" },
        { text: "Antal besök", align: "left", value: "" },
      ],
      createdexperience: [
        { text: "Kommun", align: "left", value: "countyName" },
        { text: "Nya upplevelser", align: "left", value: "newExperience" },
        { text: "Totalt antal upplevelser (ändras inte på datum)", align: "center", value: "totalExperiences" },
      ],
      experiencevisits: [
        { text: "Upplevelsenamn", align: "left", value: "experienceName" },
        { text: "Kommun", align: "left", value: "countyName" },
        { text: "Antal besök", align: "left", value: "totalVisits" },
      ],
      footerProps: { "items-per-page-options": [20, 100] },
      statisticsresult: [],
      statsdata: [],
      experienceVisitsData: [],
      experienceresult: [],
      newplayers: [],
      series: [
         {
          name: "Spelare",
          data: [],
        },
      ],
      noData: {
        text: 'Loading...'
      },
      chartOptions: {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        title: {
          text: "Nya spelare",
          align: "left",
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
      },
      series2: [
        {
          name: "Spelare",
          data: [],
        },
      ],
      chartOptions2: {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        title: {
          text: "Aktiva spelare",
          align: "left",
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
      },
    };
  },
  created() {
    this.updatereport();
  },
  methods: {
    updatereport() {
      HTTP.post("/statistics/get", {
        datestart: this.datestart,
        dateend: this.dateend,
      }).then((response) => {
        var data = response.data
        this.statsdata = data.createdexperiences;
        this.experienceVisitsData = data.experiencevisits;
        this.series = [{data: data.series.newPlayersChart}]
        this.series2 = [{data: data.series.totalPlayersChart}]
      });
    },

    selectdate() {
      this.menustart = false;
      this.menuend = false;
      this.updatereport();
    },
    clearfilter() {
      this.datestart = null;
      this.dateend = null;
      this.updatereport();
    },
   },
};
</script>

<style scoped>
.v-sheet--offset {
  top: -24px;
  position: relative;
}
</style>