<template>
    <div>
    <appbar></appbar>
    <v-row justify="center" class="mt-5">
      <v-col md="8" sm="12">
        <v-data-table dense :search="search"
          :headers="headers"
          :items="tableItems"
          :items-per-page="20"
          :footer-props="footerProps"
          class="elevation-1">
          <template v-slot:top>
            <v-row class="d-flex align-start mx-5" >            
              <v-col cols=12>
                <h2>Administratörer</h2>
              </v-col>
            </v-row>
            <v-row class="d-flex align-start mx-5" >
            </v-row>
            <v-row class="d-flex align-center mx-5" >
              <v-col cols=9>
                <v-text-field v-model="search" label="Search"></v-text-field>
              </v-col>
              <v-col cols=3>
                <v-btn tile outlined @click="newItem()"><v-icon small class="py-2 mr-2">mdi-flip-to-front</v-icon> Ny</v-btn>
              </v-col>                  
            </v-row>
          </template>     
          <template v-slot:item.action="{ item }">
            <v-icon medium class="mr-2 pa-2" @click="editItem(item)">mdi-pencil</v-icon>
            <v-icon medium class="pa-2" @click="deleteItem(item)">mdi-delete</v-icon>
          </template>
        </v-data-table>

            <v-dialog persistent v-model="dialog" max-width="800px" class="dialogbox">
              <v-card>
                <v-card-title>
                  <span class="headline">Skapa ny administratör</span>
                </v-card-title>  
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col >
                        <v-text-field v-model="editedItem.firstname" label="Förnamn"></v-text-field>
                      </v-col>
                      <v-col >
                        <v-text-field v-model="editedItem.lastname" label="Efternamn"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                    <v-col>
                    <v-select
                        v-model="editedItem.regionId"
                        label="Region"
                        :items="dialoglists.regions"
                        item-text="regionName"
                        item-value="id"
                        required
                    ></v-select>
                  </v-col>
                    </v-row>
                    <v-row>
                      <v-col >
                        <v-text-field v-model="editedItem.email" label="E-post"></v-text-field>
                      </v-col>
                      <v-col >
                        <!-- <v-text-field v-model="editedItem.lastname" label="Password"></v-text-field> -->
                        <v-text-field v-model="editedItem.password" :append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'" 
                        :rules="[rules.required, rules.min]" :type="showpassword ? 'text' : 'password'" 
                        label="Password" hint="At least 4 characters" counter @click:append="showpassword = !showpassword"></v-text-field>
                      </v-col>
                    </v-row>

                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn tile outlined class="mb-3" @click="dialog=false"><v-icon small class="py-2 mr-1">mdi-close</v-icon> Avbryt</v-btn>
                  <v-btn tile outlined class="mb-3 mr-3" @click="save()"><v-icon small class="py-2 mr-1">mdi-content-save</v-icon> Spara</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

      </v-col>
    </v-row>
    </div>
</template>

<script>

import { HTTP } from '@/plugins/backendAPI'

export default {
  data () {
    return {
      dialog: false,
      search: '',
      footerProps: {'items-per-page-options': [50, 100]},
      headers: [
        { text: 'Förnamn', value: 'firstname' },
        { text: 'Efternamn', value: 'lastname' },
        { text: 'Region', value: 'regionName' },
        { text: 'E-post', value: 'email', width:130 },
        { text: 'Redigera', value: 'action', sortable: false, width:130 }
      ],   
      editedItem: {},
      tableItems: [], 
      dialoglists: {},
      rules: {
          required: value => !!value || 'Required.',
          min: v => v.length >= 4 || 'Min 4 characters'
      },
      showpassword: false
    }
  },
  created () {
    this.getitems()
  },
  methods: {
    getitems () {
      HTTP.get('/users/getadmins').then(response => {
        this.tableItems = response.data
      })
    },
    newItem () {
      this.editedItem = { id:0, firstname:null, lastname:null, email:null, password:null, isAdmin:true}
      this.editItem(this.editedItem)
    },    
    editItem (item) {
      this.editedItem = Object.assign({}, item)
      HTTP.get("/experience/gettypes").then((response) => {
          this.dialoglists = response.data;
        });
        this.showpassword = false;
        this.dialog = true
    },
    deleteItem (item) {
      var self=this
      this.$root.$confirm.open('Ta bort rättigheter', 'Ta bort administrationsrättigheterna på användaren.', { color: 'red' }).then((response) => {
        if (response) {
          HTTP.post('/users/deleterights',{id: item.id}).then(() => {
            self.tableItems.splice(self.tableItems.indexOf(item), 1)
          })
        }
      })
    },
    save () {
      var self=this
      HTTP.post('/users/create/admin', self.editedItem).then(response => {
          if (self.editedItem.id > 0) {
            var editedIndex = self.tableItems.findIndex(q => q.id === self.editedItem.id)
            self.tableItems[editedIndex].firstname = self.editedItem.firstname
            self.tableItems[editedIndex].lastname = self.editedItem.lastname
            self.tableItems[editedIndex].regionId = self.editedItem.regionId
            self.tableItems[editedIndex].email = self.editedItem.email
          } else {
            self.editedItem.id = response.data
            self.tableItems.push(this.editedItem)
          }
          self.dialog=false
      }).catch((e) => {
          self.$store.dispatch('showError', 'Fel vid sparning: ' + e.response.data)
      })
    }
  }
}
</script>