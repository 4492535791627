import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors'
import mobileappbar from '@/components/mobileappbar'
import appbar from '@/components/appbar'
import VueQrcode from '@chenfengyuan/vue-qrcode';
import vuemoment from 'vue-moment'

Vue.use(vuemoment)
Vue.use(Vuetify);
Vue.component('mobileappbar', mobileappbar)
Vue.component('appbar', appbar)
Vue.component(VueQrcode.name, VueQrcode);

Vue.filter('dateonly', function (value) {
  if (value) {
    return Vue.moment(value).format('YYYY-MM-DD')
  }
  return null
})

Vue.filter('longdate', function (value) {
  return Vue.moment(value).format('YYYY-MM-DD HH:mm')
})

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: colors.purple,
        secondary: colors.grey.darken1,
        accent: colors.shades.black,
        error: colors.red.accent3,
      },
      dark: {
        primary: 'colors.blue.lighten3',
      },
    },
  },
});
